export var checkWhoIsWho = function (user_role, isUserSuperUser) {
    var url_to_profile = "";
    if (user_role === 1 && isUserSuperUser)
        url_to_profile = "/admin";
    if (user_role === 1 && !isUserSuperUser)
        url_to_profile = "/company_profile/";
    if (user_role === 2)
        url_to_profile = "/expert_profile";
    if (user_role === 3)
        url_to_profile = "/user_profile";
    if (user_role === 4)
        url_to_profile = "/discont_profile";
    return url_to_profile;
};
