export var getUserTimeZone = function () {
    if (typeof Intl === "undefined") {
        console.log("Intl API not supported");
        return;
    }
    // Get the user's time zone
    var userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimeZone;
};
export var generateTimezoneFormatForDate = function (timezone) {
    return timezone < 0 ? "-0".concat(Math.abs(timezone), ":00") : "+0".concat(Math.abs(timezone), ":00");
};
//time zones list
export var TIME_ZONES = {
    "Europe/London": 0,
    "Europe/Paris": 1,
    "Europe/Berlin": 1,
    "Europe/Moscow": 3,
    "Europe/Istanbul": 3,
    "Europe/Athens": 2,
    "Europe/Rome": 1,
    "Europe/Minsk": 3,
    "Europe/Stockholm": 1,
    "Europe/Zurich": 1,
    "Europe/Prague": 1,
    "America/New_York": -5,
    "America/Los_Angeles": -8,
    "America/Chicago": -6,
    "America/Toronto": -5,
    "America/Denver": -7,
    "America/Vancouver": -8,
    "Asia/Tokyo": 9,
    "Asia/Dubai": 4,
    "Asia/Shanghai": 8,
    "Asia/Kolkata": 5,
    "Australia/Sydney": 10,
};
