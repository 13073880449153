export var checkNameForUserOrCompany = function (user_role, is_super_user, user) {
    var _a, _b, _c, _d, _e, _f;
    var profile_name = "";
    var companyOrUser = "";
    if (user_role === 1 && is_super_user) {
        profile_name = user === null || user === void 0 ? void 0 : user.username;
        companyOrUser = "";
    }
    if (user_role === 1 && !is_super_user) {
        profile_name = "".concat(user === null || user === void 0 ? void 0 : user.info.name, " ").concat((_a = user === null || user === void 0 ? void 0 : user.info) === null || _a === void 0 ? void 0 : _a.status);
        companyOrUser = "";
    }
    if (user_role === 2) {
        companyOrUser = "";
        profile_name = (user === null || user === void 0 ? void 0 : user.info) !== undefined ? "".concat((_b = user === null || user === void 0 ? void 0 : user.info) === null || _b === void 0 ? void 0 : _b.first_name, " ").concat((_c = user === null || user === void 0 ? void 0 : user.info) === null || _c === void 0 ? void 0 : _c.last_name) : "";
    }
    if (user_role === 4) {
        companyOrUser = "";
        profile_name = (user === null || user === void 0 ? void 0 : user.info) !== undefined ? "".concat((_d = user === null || user === void 0 ? void 0 : user.info) === null || _d === void 0 ? void 0 : _d.name) : "";
    }
    if (user_role === 3) {
        companyOrUser = "";
        profile_name = (user === null || user === void 0 ? void 0 : user.info) !== undefined ? "".concat((_e = user === null || user === void 0 ? void 0 : user.info) === null || _e === void 0 ? void 0 : _e.first_name, " ").concat((_f = user === null || user === void 0 ? void 0 : user.info) === null || _f === void 0 ? void 0 : _f.last_name) : "";
    }
    return "".concat(companyOrUser, " ").concat(profile_name);
};
