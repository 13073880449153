var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from "dayjs";
import Watch_icon from "../../assets/img/Watch_icon.svg";
import CalendarIcon from "../../assets/img/calendar_event_icon.svg";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export var getDisplayedDate = function (publication, style, type) {
    var _a, _b, _c, _d;
    var dateOpen = (_a = dayjs(publication === null || publication === void 0 ? void 0 : publication.datetime_open)) === null || _a === void 0 ? void 0 : _a.format("DD.MM.YYYY");
    var dateClose = (_b = dayjs(publication === null || publication === void 0 ? void 0 : publication.datetime_close)) === null || _b === void 0 ? void 0 : _b.format("DD.MM.YYYY");
    var timeOpen = (_c = dayjs(publication === null || publication === void 0 ? void 0 : publication.datetime_open)) === null || _c === void 0 ? void 0 : _c.format("HH:mm");
    var timeClose = (_d = dayjs(publication === null || publication === void 0 ? void 0 : publication.datetime_close)) === null || _d === void 0 ? void 0 : _d.format("HH:mm");
    if (type === "fullpost" && (publication === null || publication === void 0 ? void 0 : publication.datetime_open) && (publication === null || publication === void 0 ? void 0 : publication.datetime_close)) {
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: style.event_time }, { children: [_jsx("img", { className: style.eventIcon, src: CalendarIcon }), _jsxs("span", { children: ["\u0414\u0430\u0442\u0430 \u0441\u043E\u0431\u044B\u0442\u0438\u044F: c ", dateOpen, " \u043F\u043E ", dateClose] })] })), _jsxs("div", __assign({ className: style.event_time }, { children: [_jsx("img", { className: style.eventIcon, src: Watch_icon }), _jsxs("span", { children: ["\u0412\u0440\u0435\u043C\u044F \u0441\u043E\u0431\u044B\u0442\u0438\u044F: c ", timeOpen, " \u043F\u043E ", timeClose] })] }))] }));
    }
    else if (type === "fullpost" && (publication === null || publication === void 0 ? void 0 : publication.datetime_open)) {
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: style.event_time }, { children: [_jsx("img", { className: style.eventIcon, src: CalendarIcon }), _jsxs("span", { children: ["\u0414\u0430\u0442\u0430 \u0441\u043E\u0431\u044B\u0442\u0438\u044F: ", dateOpen] })] })), _jsxs("div", __assign({ className: style.event_time }, { children: [_jsx("img", { className: style.eventIcon, src: Watch_icon }), _jsxs("span", { children: ["\u0412\u0440\u0435\u043C\u044F \u0441\u043E\u0431\u044B\u0442\u0438\u044F: ", timeOpen] })] }))] }));
    }
    else if (type === "card" && (publication === null || publication === void 0 ? void 0 : publication.datetime_open)) {
        return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: style.event_time }, { children: [_jsx("img", { className: style.eventIcon, src: CalendarIcon }), _jsxs("span", { children: ["\u0414\u0430\u0442\u0430 \u0441\u043E\u0431\u044B\u0442\u0438\u044F: c ", dateOpen] })] })) }));
    }
    else {
        return (_jsxs("div", __assign({ className: style.event_time }, { children: [_jsx("img", { className: style.eventIcon, src: CalendarIcon }), _jsx("span", { children: "\u0414\u0430\u0442\u0430 \u0441\u043E\u0431\u044B\u0442\u0438\u044F: \u043D\u0435 \u0443\u043A\u0430\u0437\u0430\u043D\u0430" })] })));
    }
};
