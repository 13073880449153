import * as yup from "yup";
var passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export var passwordsSchema = yup.object().shape({
    oldPass: yup.string().required("Необходимое поле"),
    newPassword1: yup
        .string()
        .required("Необходимое поле")
        .matches(passwordRules, "Пароль должен содержать латинские символы, минимум одну заглавную букву и минимум 1 цифру"),
    newPassword2: yup
        .string()
        .required("Необходимое поле")
        .oneOf([yup.ref("newPassword1")], "Пароли не совпадают"),
});
